import { Capacitor } from '@capacitor/core'
import type { AppRouter } from '@sup/cr/server/__app'
import { MutationCache } from '@tanstack/react-query'
import { TRPCClientError, httpBatchLink, loggerLink } from '@trpc/client'
import { toast } from 'sonner'
import { createTRPCRemix } from './createTRPCRemix'

const url = '/api/trpc'

const mutationCache = new MutationCache({
	onSuccess(_data, _variables, _context, mutation) {
		if (mutation?.meta?.toast?.success) {
			toast.success(mutation.meta.toast.success.message, {
				description: mutation.meta.toast.success.description,
			})
		}
	},
	onError(error, _variables, _context, mutation) {
		if (error instanceof TRPCClientError && error.data?.prismaError) {
			if (error.data.prismaError.meta.code === '23505') {
				toast.error('Item already exists.')
				return
			}
		}

		if (mutation?.meta?.toast?.error !== false) {
			toast.error('Something went wrong', {
				description: "We've been notified and are looking into it.",
			})
		}
	},
})

export const trpc = createTRPCRemix<AppRouter>({
	config() {
		return {
			queryClientConfig: {
				mutationCache,
			},
			links: [
				Capacitor.getPlatform() === 'web' ? loggerLink() : undefined,
				httpBatchLink({
					url,
					headers() {
						const app = window.location.pathname.split('/')[1]
						return { 'X-Sup-App': app }
					},
				}),
			].filter(Boolean),
		}
	},
})

interface Meta {
	mutationMeta: {
		toast?: {
			success?: {
				message: string
				description: string
			}
			error: false
		}
	}
}

declare module '@tanstack/react-query' {
	interface Register extends Meta {}
}
