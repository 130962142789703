import type {
	CreateReactUtilsProxy,
	DecoratedProcedureRecord,
	TRPCUseQueries,
} from '@trpc/react-query/shared'
import {
	createHooksInternal,
	createReactProxyDecoration,
	createReactQueryUtilsProxy,
} from '@trpc/react-query/shared'
import type { AnyRouter, ProtectedIntersection } from '@trpc/server'
import { createFlatProxy } from '@trpc/server/shared'
import { useMemo } from 'react'
import type { WithTRPCNoSSROptions } from './withTRPC'
import { withTRPC } from './withTRPC'

interface CreateTRPCRemixBase<TRouter extends AnyRouter> {
	useContext(): CreateReactUtilsProxy<TRouter, {}>
	withTRPC: ReturnType<typeof withTRPC<TRouter>>
	useQueries: TRPCUseQueries<TRouter>
}

/**
 * @internal
 */
type CreateTRPCRemix<TRouter extends AnyRouter, TFlags> = ProtectedIntersection<
	CreateTRPCRemixBase<TRouter>,
	DecoratedProcedureRecord<TRouter['_def']['record'], TFlags>
>

export function createTRPCRemix<TRouter extends AnyRouter, TFlags = Record<string, never>>(
	opts: WithTRPCNoSSROptions<TRouter>,
): CreateTRPCRemix<TRouter, TFlags> {
	const hooks = createHooksInternal<TRouter>({})

	const _withTRPC = withTRPC(opts)

	return createFlatProxy((key) => {
		if (key === 'useContext') {
			return () => {
				const context = hooks.useContext()

				return useMemo(() => {
					return (createReactQueryUtilsProxy as any)(context)
				}, [context])
			}
		}

		if (key === 'useQueries') {
			return hooks.useQueries
		}

		hooks.useUtils

		if (key === 'withTRPC') {
			return _withTRPC
		}

		return createReactProxyDecoration(key, hooks)
	})
}
